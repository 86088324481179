import { mapGetters } from 'vuex'

const recaptchaV3 = {
  computed: {
    ...mapGetters(['showCaptchav2']),
    isRecaptchav3 () {
      const recaptchaV3Flag = Number(process.env.VUE_APP_RECAPTCHA_VERSION)
      return recaptchaV3Flag === 3 && !this.showCaptchav2
    }
  },
  methods: {
    async generateRecaptchav3 () {
      if (!this.isRecaptchav3) {
        return null
      }

      await this.$recaptchaLoaded()
      return this.$recaptcha('login')
    },
  }
}

export default recaptchaV3
