<template>
  <div
    v-if="!isIE"
    id="app"
    class="jade"
  >
    <router-view />
  </div>
</template>
<script>

import { datadogInitialize } from '@/utils/datadog'

export default {
  name: 'App',
  computed: {
    appVersion () {
      return this.$store.state.appVersion
    },
    isIE () {
      return window.isIE
    },
    domain () {
      return this.$store.state.domain
    }
  },
  created () {
    datadogInitialize()
  },
  mounted () {
    const meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  beforeCreate () {
    if (['jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME))
      this.$store.dispatch('updateWhitelabel', {
        enabled: true,
        theme: process.env.VUE_APP_THEME
      })
  }
}
</script>

<style>
#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
  z-index: 100;
}

@keyframes popin {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.popin-animation {
  animation: popin 0.5s ease-in-out;
}

@keyframes resize {
  0% {
    max-height: 300px;
  }

  100% {
    max-height: 900px;
  }
}
.resize-animation {
  animation: resize 1s ease-in-out, popin 0.5s ease-in-out;
}

@media only screen and (min-width: 768px) {
  .two-fa-container {
    min-width: 563px;
  }
}
</style>
