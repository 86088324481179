import packagejson from '../../package.json'
import { datadogRum } from '@datadog/browser-rum'

export function datadogInitialize () {
  const enabled = process.env.VUE_APP_DATADOG_ENABLE === 'true' || process.env.NODE_ENV !== 'development'

  if (!enabled) return

  const applicationId = process.env.VUE_APP_DATADOG_APP_ID
  if (!applicationId) throw new Error('Datadog Application ID not found')

  const clientToken = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
  if (!clientToken) throw new Error('Datadog Client Token not found')

  const costCenter = process.env.VUE_APP_DATADOG_COST_CENTER
  if (!costCenter) throw new Error('Datadog Cost Center not found')

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    service: 'mundipagg/id-webapp',
    env: process.env.VUE_APP_CORE_ENV,
    version: packagejson.version,
    sessionReplaySampleRate: 0,
    sessionSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event, context) => {

      // Replace sensitive tokens in URLs with generic values
      if (/token=[a-f0-9]{32}/.test(event.view.url)) {
        event.view.url = event.view.url.replace(/token=[a-f0-9]{32}/g, 'token=TOKEN]')
      }

      if (/\/validated\/[a-f0-9]{32}/.test(event.view.url)) {
        event.view.url = event.view.url.replace(/\/validated\/[a-f0-9]{32}/g, '/validated/[TOKEN]')
      }

      return true
    }
  })

  datadogRum.setGlobalContext({
    owner: 'stone-payments/payments-experience-acquisition',
    project: 'mundipagg/id-webapp',
    'cost-center': costCenter,
    support: 'stone-payments/payments-experience-acquisition',
  })
}
