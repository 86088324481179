<template>
  <emd-footer-internal
    :switchLanguage="switchLanguage"
    :switchThemeMode="switchThemeMode"
    :privacySecurity="privacySecurity"
    :help="help"
    :termsOfUse="termsOfUse"
    :privacyPolicy="privacyPolicy"
    @closeTermsOfUseModal="closeTermsOfUseModal"
    @changeLanguage="changeLanguage"
    @changeThemeMode="changeThemeMode"
  />
</template>

<script>
import { EmdFooterInternal } from 'emerald-vue3/src/components'

export default {
  name: 'EmdFooter',
  data: () => ({
    darkMode: false
  }),
  components: {
    EmdFooterInternal
  },
  computed: {
    switchLanguage () {
      return {
        show: this.componentIsEnabled('footer', 'switchLanguage'),
        value: this.pref.language
      }
    },
    switchThemeMode () {
      return {
        show: this.componentIsEnabled('footer', 'switchThemeMode'),
        isDark: this.darkMode,
        position: 'relative',
        align: 'center'
      }
    },
    privacySecurity () {
      return {
        show: this.componentIsEnabled('footer', 'privacySecurity'),
        url: process.env.VUE_APP_URL_PRIVACY_SECURITY
      }
    },
    help () {
      return {
        show: this.componentIsEnabled('footer', 'help'),
        url: process.env.VUE_APP_URL_HELP
      }
    },
    termsOfUse () {
      const termsOfUseInline = {
        modalExternal: true,
        showModal: this.$store.getters.showTermsOfUseModal
      }
      const termsOfUseInList = {
        show: this.componentIsEnabled('footer', 'termsOfUse'),
        url: process.env.VUE_APP_URL_TERMS_OF_USE,
        component: 'a'
      }
      return this.routeIsValidate ? termsOfUseInline : termsOfUseInList
    },
    privacyPolicy () {
      if (this.routeIsValidate) return { show: false }
      else {
        return {
          show: this.componentIsEnabled('footer', 'privacyPolicy'),
          url: process.env.VUE_APP_URL_PRIVACY_POLICY
        }
      }
    },
    routeIsValidate () {
      return this.$route.name === 'profile'
    },
    pref () {
      return this.$store.getters.pref
    }
  },
  methods: {
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    changeLanguage (lang) {
      if (lang) this.$i18n.locale = lang
      this.$store.dispatch('SET_LANGUAGE', lang)
    },
    changeThemeMode (mode) {
      this.$store.dispatch('SET_THEME_MODE', mode)
    },
    getPreferences () {
      this.$store.dispatch('GET_PREFERENCES').then(() => {
        if (this.$i18n) this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE
        this.changeLanguage(this.$i18n.locale)
        this.darkMode = this.pref.appearance.mode !== 'light'
      })
    },
    closeTermsOfUseModal () {
      this.$store.dispatch('SET_TERMS_OF_USE_MODAL')
    }
  },
  beforeMount: function () {
    this.getPreferences()
  }
}
</script>
