<template>
  <div>
    <global-loader v-if="isLoading && !hasError" />
    <global-error
      v-if="!isLoading && hasError"
      :showLogo="componentIsEnabled('globalError', 'logo')"
      :code="errorCode"
      :theme="theme"
    />
    <div
      v-if="!isLoading && !hasError"
      class="el-centered fade-in"
    >
      <div
        class="el-stripe"
        style="margin: auto"
      >
        <div class="el-bounds _narrow">
          <div class="el-centered__main">
            <!-- Login -->
            <div class="el-centered__main_body">
              <div class="el-section">
                <router-view></router-view>
              </div>
              <p
                class="emd-text _color_subtle _type_caption _align_center"
                v-if="isTwoFAResetSac">{{ $t('SERVICE-PHONE') }}</p>
              <!-- Footer -->
              <emd-footer class="eu-mt_2"></emd-footer>
              <!-- ./Footer -->
            </div>
            <!-- /Login -->
          </div>
        </div>
      </div>
      <two-fa-reset-success></two-fa-reset-success>
      <tfa-whatsapp-optin></tfa-whatsapp-optin>
      <two-fa-reset-code-confirm-copy></two-fa-reset-code-confirm-copy>
    </div>
  </div>
</template>

<script>
import EmdFooter from '@/components/emerald/organisms/EmdFooter.vue'
import GlobalLoader from '@/components/project/globals/GlobalLoader.vue'
import { GlobalError } from 'emerald-vue3/src/components'
import TwoFaResetSuccess from '@/views/TwoFaReset/TwoFaResetSuccess.vue'
import TwoFaResetCodeConfirmCopy from '@/views/TwoFaReset/TwoFaResetCodeConfirmCopy.vue'
import TfaWhatsappOptin from '@/views/TwoFaLogin/TfaWhatsappOptin.vue'

export default {
  name: 'SignTemplate',
  components: {
    EmdFooter,
    GlobalError,
    GlobalLoader,
    TwoFaResetSuccess,
    TwoFaResetCodeConfirmCopy,
    TfaWhatsappOptin
  },
  data () {
    return {
      faqUrl: process.env.VUE_APP_URL_FAQ
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.globalLoading
    },
    hasError () {
      return this.$store.state.globalError
    },
    pref () {
      return this.$store.getters.pref
    },
    errorCode () {
      return this.$store.getters.GET_GLOBAL_ERROR_CODE
    },
    theme () {
      return process.env.VUE_APP_THEME
    },
    isPagarmeTheme () {
      return ['pagarme'].includes(process.env.VUE_APP_THEME)
    },
    isTwoFAConfig () {
      return this.$route.path.includes('twofaconfig')
    },
    isTwoFAResetSac () {
      return this.$route.path.includes('twofaresetsac')
    }
  },
  mounted () {
    const body = document.body
    const preferences = {...this.pref}
    if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
      this.$store.dispatch('FORCE_LIGHT_MODE')
      preferences.appearance.mode = 'light'
    }
    if (preferences.appearance.mode === 'light') {
      body.classList.remove('_mode_dark')
    } else {
      body.classList.add('_mode_dark')
    }
    this.$store.dispatch('SET_PREFERENCES', preferences)

  },
  methods: {
    goToSignin () {
      this.$router.push({ name: 'signin' })
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
<style>
.emd-list svg {
  display: inline-block;
  vertical-align: top;
}
.el-centered__main_aside .emd-list .emd-text {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  width: 85%;
}
._color_pagarme {
  color: #65a300;
}
</style>
