import { createI18n } from 'vue-i18n'

function loadLocaleMessages () {
  const locales = require.context(
    './locales',
    true,
    /([a-z]{2}-[A-Z]{2})+\.json$/i
  )
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([a-z]{2}-[A-Z]{2})+\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'pt-BR',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-BR',
  messages: loadLocaleMessages(),
  globalInjection: true
})

export default i18n
