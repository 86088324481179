<template>
  <div class="emd-container _footer_centered _highlight_footer">
    <div class="emd-container__header">
      <h1 class="emd-text _size_subpage _color_default">
        {{ $t('REGISTER.TITLE') }}
      </h1>
    </div>
    <form
      @submit.prevent="validateForm"
      ref="formSignup"
      novalidate="true"
    >
      <div class="emd-container__content">
        <emd-alert
          variant="danger"
          v-if="registerFormError"
          @cancel="registerFormError = false"
          :title="''"
        >
          <template v-slot:content>
            <p
              v-for="(error, index) in errors"
              :key="index"
            >
              {{ error }}
            </p>
          </template>
        </emd-alert>

        <!-- Nome -->
        <emd-form-group
          data-cy="signup-form-group-first-name"
          :state="!firstNameIsValid.valid ? 'error' : ''"
          :label="$t('REGISTER.NAME-LABEL')"
          labelFor="firstName"
          :feedbacks="[
            {
              msg: firstNameIsValid.tooShort
                ? `${$t('REGISTER.ERROR-TEXT')} 3 ${$t(
                    'REGISTER.ERROR-TEXT_LEAD'
                  )}`
                : $t('REGISTER.REQUIRED-TEXT'),
              condition: !firstNameIsValid.valid,
              dataCy: 'signup-input-feedback'
            }
          ]"
        >
          <emd-input
            data-cy="signup-first-name"
            name="firstName"
            type="text"
            :minLength="3"
            :maxLength="30"
            required
            :onInput="validateName"
            v-model="firstName"
          />
        </emd-form-group>
        <!-- ./Nome -->

        <!-- Sobrenome -->
        <emd-form-group
          data-cy="signup-form-group-last-name"
          :state="!lastNameIsValid.valid ? 'error' : ''"
          :label="$t('REGISTER.SURNAME-LABEL')"
          labelFor="lastName"
          :feedbacks="[
            {
              msg: lastNameIsValid.tooShort
                ? `${$t('REGISTER.ERROR-TEXT')} 3 ${$t(
                    'REGISTER.ERROR-TEXT_LEAD'
                  )}`
                : $t('REGISTER.REQUIRED-TEXT'),
              condition: !lastNameIsValid.valid
            }
          ]"
        >
          <emd-input
            data-cy="signup-last-name"
            name="lastName"
            type="text"
            :minLength="3"
            :maxLength="40"
            required
            :onInput="validateName"
            v-model="lastName"
          />
        </emd-form-group>
        <!-- ./Sobrenome -->

        <!-- Email -->
        <emd-form-group
          data-cy="signup-form-group-email"
          :state="!emailIsValid.valid ? 'error' : ''"
          :label="$t('REGISTER.EMAIL-LABEL')"
          labelFor="email"
          :feedbacks="[
            {
              msg: $t('REGISTER.ERROR-TEXT_ALT'),
              condition: !emailIsValid.valid
            }
          ]"
        >
          <emd-input
            data-cy="signup-email"
            name="email"
            type="email"
            required
            :maxlength="320"
            v-model="email"
            :onInput="validateEmail"
          />
        </emd-form-group>
        <!-- ./Email -->

        <!-- Continuar -->
        <div class="emd-text _align_right">
          <button
            data-cy="signup-submit"
            type="submit"
            class="emd-btn _color_primary"
            :disabled="!formIsValid || registerFormIsLoading"
          >
            <emd-loader
              v-if="registerFormIsLoading"
              type="_subtle"
            ></emd-loader>
            <span v-else>
              {{ $t('REGISTER.BUTTON-TEXT') }}
            </span>
          </button>
        </div>
        <!-- ./Continuar -->
      </div>

      <!-- Ações -->
      <div class="emd-container__footer">
        <p class="emd-text _color_default _align_center">
          {{ $t('REGISTER.BACK-LABEL') }}
          <router-link
            class="emd-link _color_primary"
            :to="{ name: 'signin' }"
            :disabled="registerFormIsLoading"
            >{{ $t('REGISTER.BACK-BUTTON') }}
          </router-link>
        </p>
      </div>
      <!-- ./Ações -->
    </form>
  </div>
</template>

<script>
import { formatErrorMessage, inputValidate } from '@/support'
import { UtilitiesInstance } from '@/store'
import {
  EmdLoader,
  EmdAlert,
  EmdInput,
  EmdFormGroup
} from 'emerald-vue3/src/components'

export default {
  name: 'Signup',
  components: {
    EmdAlert,
    EmdLoader,
    EmdInput,
    EmdFormGroup
  },
  data () {
    return {
      registerFormIsLoading: false,
      registerFormError: false,
      errors: [],
      firstName: '',
      firstNameIsValid: {
        valid: true
      },
      lastName: '',
      lastNameIsValid: {
        valid: true
      },
      email: '',
      emailIsValid: {
        valid: true
      }
    }
  },
  computed: {
    formIsValid () {
      if (
        this.firstName.length > 0 &&
        this.lastName.length > 0 &&
        this.email.length > 0
      ) {
        if (
          this.firstNameIsValid.valid &&
          this.lastNameIsValid.valid &&
          this.emailIsValid.valid
        ) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    validateForm (e) {
      e.preventDefault()
      this.registerFormIsLoading = true
      this.$store
        .dispatch('CREATE_REGISTER', {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email
        })
        .then(res => {
          this.registerFormIsLoading = false
          this.$store.dispatch('UPDATE_FEEDBACK_TYPE', 'after-register')
          this.$store.commit('SET_USER_EMAIL', this.email)
          this.$router.push({ name: 'feedback' }).catch(err => err)
        })
        .catch(err => {
          this.registerFormError = true
          this.registerFormIsLoading = false
          this.errors = formatErrorMessage(
            err,
            this.$t('REGISTER.GENERIC-ERROR')
          )
        })
    },
    validateName (e) {
      const keyIsValid = e.target.name + 'IsValid'
      e.target.value = UtilitiesInstance.commons.removeSpecialCharacters(
        e.target.value
      )
      this[e.target.name] = e.target.value
      this[keyIsValid] = inputValidate(this[e.target.name], e.target)
    },
    validateEmail (e) {
      e.target.value = UtilitiesInstance.commons
        .removeSpecialCharacters(e.target.value, 'email')
        .toLowerCase()
      this.email = e.target.value
      this.emailIsValid = e.target.validity
    },
    hideAlert () {
      this.registerFormError = false
    }
  }
}
</script>
