<template>
  <div
    class="el-chunky eu-pa_5 emd-modal"
    :class="{ _active: isActive }"
  >
    <div class="emd-modal__content">
      <div class="emd-container _highlight_header">
        <div class="emd-container__header">
          <h2 class="emd-text _size_section _type_caption">
            {{ $t('TWOFA-RESET-CODE-COPY-CONFIRM.TITLE') }}
          </h2>
        </div>

        <div class="emd-container__content">
          <p class="emd-text _color_default _size_section _type_normal">
            {{ $t('TWOFA-RESET-CODE-COPY-CONFIRM.DESCRIPTION') }}
          </p>
        </div>
        <div class="emd-container__footer el-chunky eu-px_2">
          <button
            :disabled="isLoading"
            @click="login"
            class="emd-btn _color_primary eu-grow_1"
          >
            <emd-loader
              v-if="isLoading"
              type="_subtle"
            ></emd-loader>
            <template v-else>{{ $t('TWOFA-RESET-CODE-COPY-CONFIRM.CONFIRM-BUTTON') }}</template>
          </button>

          <button
            :disabled="isLoading"
            @click="backToCopyCode"
            class="emd-btn eu-align_center _color_primary _type_link eu-grow_1"
          >{{ $t('TWOFA-RESET-CODE-COPY-CONFIRM.BACK-BUTTON') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader } from 'emerald-vue3/src/components'

export default {
  name: 'TwoFaresetCodeConfirmCopy',
  components: {
    EmdLoader
  },
  computed: {
    isActive () {
      return this.$store.state.modal.resetCodeConfirmCopy.open
    },
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async login () {
      this.isLoading = true
      this.$store.dispatch('LOGIN')
    },
    backToCopyCode () {
      this.$store.commit('userReturnForCopy')
      this.$store.commit('updateResetCodeConfirmCopy')
    }
  }
}
</script>
