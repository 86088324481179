const useFingerPrint = JSON.parse(String(process.env.VUE_APP_USE_FINGERPRINT))

const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME,
  useFingerPrint
}

const dataCoreProfile = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME,
  callbacksError: {
    unauthorized: () => {
      window.location.assign(process.env.VUE_APP_BASE_URL)
    }
  }
}

export { useFingerPrint, dataCore, dataCoreProfile }
