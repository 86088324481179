<template>
  <!-- Email -->
  <emd-form-group
    data-cy="signin-email"
    :state="emailErrorState ? 'error' : ''"
    :label="$t('LOGIN.EMAIL-LABEL')"
    labelFor="email"
  >
    <emd-input
      name="email"
      type="email"
      v-model.lazy="inputedEmail"
      class="personalize-input"
      :placeholder="$t('LOGIN.PLACEHOLDER')"
      :class="{ 'eu-shake': emailErrorState }"
      :disabled="isConfirmStep"
      @input="formIsValid"
      id="inputedEmail"
      autoFocus
      required
    />
  </emd-form-group>
</template>

<script>
import { EmdInput, EmdFormGroup } from 'emerald-vue3/src/components'

export default {
  name: 'EmailStep',
  components: {
    EmdInput,
    EmdFormGroup
  },
  props: {
    step: {
      type: String,
      required: true
    },
    emailIsValidState: {
      type: Boolean,
      required: true
    },
    emailErrorState: {
      type: Boolean,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },
  emits: ['update:email', 'update:emailErrorState', 'update:emailIsValidState'],
  computed: {
    isConfirmStep () {
      return this.step !== 'email'
    }
  },
  data () {
    return {
      inputedEmail: ''
    }
  },
  methods: {
    formIsValid (e) {
      this.inputedEmail = this.inputedEmail.toLowerCase()
      const target = e ? e.target : document.getElementById('inputedEmail')

      this.$emit(
        'update:emailIsValidState',
        target.checkValidity()
      )
      this.$emit('update:email', this.inputedEmail)
    }
  },
  created () {
    if (this.email) {
      this.inputedEmail = this.email
    }
  },
  mounted () {
    if (this.email) {
      this.formIsValid()
    }
  }
}
</script>
