const components = {
  logo: {
    jpmorgan: ['jpmorgan']
  },
  globalLoader: {
    pagarme: ['pagarme', 'jpmorgan']
  },
  globalError: {
    logo: ['pagarme']
  },
  input: {
    help: ['jpmorgan']
  },
  footer: {
    switchThemeMode: [],
    switchLanguage: ['pagarme', 'jpmorgan'],
    help: ['pagarme'],
    privacySecurity: ['jpmorgan'],
    privacyPolicy: ['pagarme'],
    termsOfUse: ['pagarme']
  },
  tfa: {
    tfaConfig: ['pagarme', 'jpmorgan'],
    tfaLogin: ['pagarme', 'jpmorgan']
  }
}

export default components
