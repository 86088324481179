import { createApp } from 'vue'
import VueGtag from 'vue-gtag-next'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import getGoogleAnalyticsSettings from './utils/googleAnalytics'
import { init as initFS } from '@fullstory/browser'
import { sanitizeUrl } from '@braintree/sanitize-url'
import 'emerald-workbench/dist/emerald.pagarme.min.css'
// Jade
import '@stone-payments/jade-design-tokens/pagarme/theme.css'
import '@stone-payments/jade-design-tokens/pagarme/fonts.css'

if (['jpmorgan', 'pagarme'].includes(process.env.VUE_APP_THEME)) {
  store.dispatch('loadEmeraldByTheme', process.env.VUE_APP_THEME)
}

const app = createApp(App)

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  initFS({ orgId: 'BKP05' })

  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  app.use(VueGtag, googleAnalyticsSettings, router)
}

app.use(router)
app.use(store)
app.use(i18n)

app.config.globalProperties.$sanitize = sanitizeUrl

const recaptchaV3Flag = Number(process.env.VUE_APP_RECAPTCHA_VERSION)
const isRecaptchaV3 = recaptchaV3Flag === 3

if (isRecaptchaV3) {
  import('vue-recaptcha-v3').then(lib => {
    app.use(lib.VueReCaptcha, {
      siteKey: process.env.VUE_APP_RECAPTCHA_KEY_V_THREE,
      loaderOptions: {
        explicitRenderParameters: {
          badge: 'bottomleft'
        }
      }
    })
  })
}

app.mount('#app')
