export function formatErrorMessage (error, defaultError) {
  let errors = []
  try {
    let arrayOfArrays = []
    const errorNotifications = error?.body?.notifications

    if (errorNotifications && Object.keys(errorNotifications).length) {
      arrayOfArrays = Object.values(errorNotifications)
      errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
    } else if (error?.body && Object.keys(error.body).length) {
      arrayOfArrays = Object.values(error.body)
      errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
    } else {
      errors = [defaultError]
    }
  } catch (e) {
    errors = [defaultError]
  }
  return errors
}
