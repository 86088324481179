const modal = {
  state: {
    resetSuccess: false,
    resetCodeConfirmCopy: {
      open: false,
      returnForCopy: false
    },
    whatsappOptin: false
  },
  mutations: {
    updateResetSuccess (state) {
      state.resetSuccess = !state.resetSuccess
    },
    updateResetCodeConfirmCopy (state) {
      state.resetCodeConfirmCopy.open = !state.resetCodeConfirmCopy.open
    },
    userReturnForCopy (state) {
      state.resetCodeConfirmCopy.returnForCopy = true
    },
    updateWhatssappOptin (state) {
      state.whatsappOptin = !state.whatsappOptin
    }
  }
}
export default modal
