<template>
  <div
    class="el-chunky eu-pa_5 emd-modal"
    :class="{ _active: isActive }"
  >
    <div class="emd-modal__content">
      <div class="emd-container _highlight_header _footer_centered">
        <div class="emd-container__header">
          <h2
            class="emd-text _size_section _color_contrast _align_center eu-mt_2"
          >
            {{ $t('TFA-WHATSAPP-OPTIN.TITLE') }}
          </h2>
        </div>
        <div class="emd-container__content">
          <emd-alert
            variant="danger"
            v-if="error"
            class="eu-basis_12 eu-mt_2 eu-mb_2"
            @cancel="error = false"
            :autoclose="true"
          >
            <template v-slot:content>
              <p
                v-for="(message, index) in errors"
                :key="index"
              >{{ message }}</p>
            </template>
          </emd-alert>
          <p class="emd-text _color_default">
            {{ $t('TFA-WHATSAPP-OPTIN.DESCRIPTION') }}
          </p>
        </div>
        <div class="emd-container__footer el-chunky eu-px_4 eu-gap_2">
          <button
            :disabled="isLoading"
            @click="backToLogin"
            class="emd-btn eu-align_center _color_primary _type_outline eu-grow_1"
          >{{ $t('TFA-WHATSAPP-OPTIN.CANCEL-BUTTON') }}
          </button>

          <button
            :disabled="isLoading"
            @click="handleOptin"
            class="emd-btn _color_primary eu-grow_1"
          >
            <emd-loader
              v-if="isLoading"
              type="_subtle"
            ></emd-loader>
            <template v-else>{{ $t('TFA-WHATSAPP-OPTIN.CONFIRM-BUTTON') }}</template>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader, EmdAlert } from 'emerald-vue3/src/components'
import recaptchaV3 from '@/mixins/recaptchaV3.js'
import twofaRequired from '@/mixins/twofaRequired'
import { mapGetters } from 'vuex'
import { InternalErrorHandler } from '@/support'

export default {
  name: 'TwoFaResetSuccess',
  components: {
    EmdLoader,
    EmdAlert
  },
  mixins: [recaptchaV3, twofaRequired],
  computed: {
    ...mapGetters(['tfaLoginBody']),
    isActive () {
      return this.$store.state.modal.whatsappOptin
    },
    email () {
      return this.tfaLoginBody?.username
    }
  },
  data () {
    return {
      isLoading: false,
      error: false,
      errors: []
    }
  },
  methods: {
    backToLogin () {
      this.$store.commit('updateWhatssappOptin')
    },
    async handleOptin () {
      try {
        await this.$store.dispatch('MFA_SEND', true)
        await this.$store.commit('SET_WHATSAPP_OPTIN', true)
        this.$store.commit('updateWhatssappOptin')
      } catch (err) {
        const genericError = this.$t('PASSWORD.GENERIC-ERROR')
        const internalErrorHandler = new InternalErrorHandler(err, genericError)

        this.errors = internalErrorHandler.handler()
        if (this.errors[0].needsTranslate) this.errors = [this.$t(this.errors[0].error)]
      }
    }
  }
}
</script>
<style scoped>
.emd-container .emd-container__footer .emd-btn {
  margin-right: 0;
}
</style>
