import components from '@/utils/components'
const roles = {
  state: {
    components: components
  },
  getters: {
    componentIsEnabled: (state, getters) => (section, component) => {
      const theme = getters.whitelabel.theme.toLowerCase()
      return state.components[section][component].includes(theme)
    }
  }
}
export default roles
